import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Cursul își propune să fie un ghid pentru părinții care doresc să-și crească copiii în așa fel încât să devină creștini maturi și responsabili. Tratează creșterea copilului ca o responsabilitate încredințată dar și ca un mare privilegiu de a investi în ei pentru gloria lui Dumnezeu.`}</MDXTag>
      <MDXTag name="p" components={components}>{`În cele 12 lecții sunt prezentate învățături biblice și sfaturi practice pentru părinți, care țin de educație, comportament și disciplinare; oferă părinților informațiile strict necesare pentru a face față datoriei în perioadele delicate ale adolescenței, ale marilor provocări ale vieții.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Aspectele foarte importante legate de colaborarea noastră cu Dumnezeu în dăltuirea caracterului pe măsură ce copiii noștri cresc și se maturizează. Recomandăm părinților cu copii mici, liderilor de tineret și de școală duminicală acest curs.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „ Când se ivesc probleme de disciplină părinții trebuie să formeze un front comun. Studiile demonstrează că lipsa de înțelegere între soți pe o problemă de disciplină poate avea urmări negative în viața copilului, care își poate pierde respectul pentru ambii părinți pentru simplu motiv că și-au asasinat reciproc autoritatea parentală. Copii ostili și mulți adolescenți agresivi provin din familii în care părinții sunt în contradicție unul cu altul.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    